:root {
  --primary: #FF9900;
  --secondary: #262C3F;
  --footerbg: #333442;
  --text: #797979;
  --body: #fff;
  --info: #2AE9E9;
  --success: #39E92A;
  --warning: #E9BF2A;
  --danger: #E96F2A;
  --black: #212529;
  --white: #F7F7FA;
  --space-0: 0rem;
  --space-1: 0.5rem;
  --space-2: 1rem;
  --space-3: 1.5rem;
  --space-4: 2rem;
  --space-5: 2.5rem;
  --space-6: 3rem;
  --space-7: 3.5rem;
  --space-8: 4rem;
  --space-9: 4.5rem;
  --space-10: 5rem;
  --container:100%;
  --header-height:7rem;
  --logo-width: 4rem;
  --logo-height: 5rem;
  --logo-size:2rem;
  --para:1.8rem;
  --title-size: 2rem;
  --large-font:3rem;
}

@media (min-width: 768px) {
  :root {
    --header-height: 10rem;
    --logo-width: 6rem;
    --logo-height: 8rem;
    --logo-size: 3.4rem;
    --large-font: 7rem;
    --title-size: 3.6rem;
    --para: 2.4rem;
    --container: 700px;
  }
}
@media (min-width: 992px) {
  :root {
    --container: 930px;
  }
}
@media (min-width: 1200px) {
  :root {
    --container: 1120px;
  }
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local(Inter), url("../fonts/Inter-Medium.woff2?v=69117260") format("woff2");
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(Inter), url("../fonts/Inter-Bold.woff2?v=69117260") format("woff2");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(Poppins), url("../fonts/Poppins-Regular.woff2?v=69117260") format("woff2");
}
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local(Poppins), url("../fonts/Poppins-Medium.woff2?v=69117260") format("woff2");
}
@font-face {
  font-family: elite-fonts;
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: local(elite-fonts), url("../fonts/elite-fonts.woff2?v=69117260") format("woff2");
}
* {
  margin: 0;
  padding: 0;
  font-family: Poppins;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  font-size: 1.4rem;
  background-color: var(--body);
  color: var(--text);
}

hr {
  border-top: 0.1rem solid black;
}

small,
label {
  font-size: 1.2rem;
}

big {
  font-size: 1.6rem;
}

sub,
sup {
  font-size: 1rem;
}

h1 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

h5 {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

h6 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
}

ul,
ol {
  padding-left: 2rem;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

blockquote {
  margin: 0 0 1rem;
}

button {
  border: none;
  color: black;
  background-color: transparent;
}
button:not(:disabled) {
  cursor: pointer;
}
button:focus {
  outline: none;
}

textarea {
  resize: vertical;
}

input:focus-within {
  outline: none;
}

iframe {
  border: 0;
}

[hidden] {
  display: none;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.6rem;
  }
  h3 {
    font-size: 2.4rem;
  }
  h4 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.8rem;
  }
  h6 {
    font-size: 1.6rem;
  }
}
.btn-site {
  padding: var(--space-1) var(--space-3);
  background: var(--primary);
  color: var(--white);
  border-radius: 1rem;
  font-weight: 500;
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  .btn-site {
    font-size: 2rem;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inner {
  margin: auto;
  max-width: var(--container);
}

.web {
  display: none;
}

main {
  padding-top: var(--header-height);
}

.para {
  font-size: var(--para);
  margin-bottom: var(--space-4);
  font-weight: 500;
}
@media (min-width: 768px) {
  .para {
    margin-bottom: var(--space-8);
  }
}

img {
  width: 100%;
}

@media (min-width: 768px) {
  .mob {
    display: none;
  }
  .web {
    display: block;
  }
}
.header-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  padding: var(--space-1);
  background-color: var(--body);
  z-index: var(--header-zindex, 100);
}

.logo-wrap {
  display: flex;
}
.logo-wrap::before {
  content: "";
  width: var(--logo-width);
  height: var(--logo-height);
  background-image: url("../images/logo.svg?v=1");
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
}
.logo-wrap .logo {
  margin-top: var(--space-2);
  margin-right: var(--space-1);
  color: var(--primary);
  font: 500 var(--logo-size) Inter;
}

.btn-hamburger {
  position: relative;
  order: 2;
  width: 3rem;
}
.btn-hamburger:before, .btn-hamburger:after,
.btn-hamburger div {
  background: var(--black);
  content: "";
  display: block;
  height: 3px;
  border-radius: 3px;
  margin: 5px 0;
  transition: 0.5s;
}
.btn-hamburger[aria-expanded=true]:before {
  transform: translateY(10px) rotate(135deg);
}
.btn-hamburger[aria-expanded=true]:after {
  transform: translateY(-6px) rotate(-135deg);
}
.btn-hamburger[aria-expanded=true] div {
  transform: scale(0);
}
.btn-hamburger[aria-expanded=true] ~ .site-nav {
  left: 0;
}

.site-nav {
  transition: 0.4s;
  background-color: var(--body);
  overflow: auto;
  position: fixed;
  top: var(--header-height);
  left: 100%;
  width: 100%;
  height: calc(100% - var(--header-height));
}
.site-nav ul {
  list-style: none;
  padding: 0;
}
.site-nav .nav-item:not(:last-child) {
  border-bottom: 0.1rem solid var(--black);
}
.site-nav .nav-anchor {
  width: 100%;
  text-align: left;
  padding: var(--space-2) var(--space-3);
  display: block;
  transition: 0.3s;
  font-weight: 500;
  font-size: 1.6rem;
}
.site-nav .nav-anchor:hover {
  color: var(--primary);
}
.site-nav .btn-nav-anchor {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.site-nav .btn-nav-anchor::after {
  content: "\e800";
  font-size: 2rem;
  font-family: elite-fonts;
  transition: 0.3s;
}
.site-nav .btn-nav-anchor[aria-expanded=true]::after {
  transform: rotate(180deg);
}
.site-nav .btn-nav-anchor[aria-expanded=true] ~ .sub-menu {
  display: block;
}
.site-nav .sub-menu {
  display: none;
  padding-block: var(--space-1);
  padding-left: var(--space-2);
}

.login-wrap {
  margin-inline: auto var(--space-2);
}

@media (min-width: 991px) {
  .header-wrap {
    padding: var(--space-2) var(--space-3);
    box-shadow: 0.1rem 0.1rem 1rem -0.5rem;
  }
  .site-nav {
    overflow: unset;
    background-color: transparent;
    position: unset;
    margin-right: var(--space-3);
  }
  .site-nav .nav-wrap {
    display: flex;
    justify-content: flex-end;
  }
  .site-nav .nav-item {
    position: relative;
  }
  .site-nav .nav-item:not(:last-child) {
    border: 0;
  }
  .site-nav .nav-anchor {
    gap: var(--space-1);
    padding-inline: var(--space-4);
  }
  .site-nav .sub-menu {
    width: 20rem;
    background-color: var(--body);
    position: absolute;
    top: 7rem;
    box-shadow: 0.1rem 0.6rem 1rem -0.5rem;
  }
  .site-nav .sub-menu .nav-item:not(:last-child) {
    border-bottom: 0.1rem solid;
  }
}
footer {
  padding: var(--space-6);
  padding-bottom: 10rem;
  background-color: var(--footerbg);
  text-align: center;
}
footer .logo-wrap {
  justify-content: center;
  margin-block: var(--space-6);
}
footer .location .text {
  font-size: 1.6rem;
  margin-bottom: var(--space-6);
  color: var(--white);
}
footer .btn-site {
  padding: var(--space-2) var(--space-6);
}

@media (min-width: 768px) {
  footer .logo-wrap::before {
    width: 15rem;
    height: 18rem;
  }
  footer .logo-wrap .logo {
    font-size: 8rem;
  }
  footer .location .text {
    font-size: 3rem;
  }
}
.showcase-banner {
  min-height: calc(100vh - var(--header-height));
  padding-block: var(--space-8);
  text-align: center;
  background-image: url("../images/showcase.webp?v=1");
  background-size: cover;
  background-position: 0 -9rem;
}
.showcase-banner .title {
  color: var(--primary);
}
.showcase-banner .title .txt {
  font-size: var(--large-font);
  display: block;
  font-weight: 500;
}
.showcase-banner .title .txt:first-child {
  font-weight: 400;
}
.showcase-banner .para {
  margin-bottom: var(--space-5);
}

.key {
  padding-top: var(--space-10);
}
.key .head {
  max-width: 70rem;
  margin: auto;
  text-align: center;
  padding-inline: var(--space-4);
}
.key .title {
  color: var(--primary);
  font: 700 var(--title-size)/1.7 Inter;
  margin-bottom: var(--space-4);
}
@media (min-width: 768px) {
  .key .title {
    margin-bottom: var(--space-8);
  }
}
.key .title span {
  display: block;
}
.key .investment-wrap {
  padding: var(--space-4);
}
.key .investment-wrap .text {
  font-size: 1.8rem;
}
@media (min-width: 768px) {
  .key .investment-wrap .text {
    font-size: 2rem;
  }
}
.key .investment-item {
  height: 7rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media (min-width: 768px) {
  .key .investment-item {
    height: 9rem;
  }
}
.key .investment-item::before {
  content: "";
  width: 0.3rem;
  height: 60%;
}
.key .investment-item.active::before {
  background-color: var(--primary);
}
.key .investment-item.active .text {
  color: var(--black);
}
.key .investment-item.active .icon {
  color: var(--primary);
}
.key .investment-item.stock .icon::before {
  content: "\e805";
}
.key .investment-item.invest .icon::before {
  content: "\e804";
}
.key .investment-item.funds .icon::before {
  content: "\e806";
}
.key .investment-item.disabled {
  cursor: not-allowed;
}
.key .investment-icon {
  flex-shrink: 0;
  margin-right: var(--space-2);
  width: 5rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .key .investment-icon {
    width: 9rem;
  }
}
.key .investment-icon .icon::before {
  content: "";
  width: 5rem;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: elite-fonts;
  font-size: 7rem;
}
@media (min-width: 768px) {
  .key .investment-icon .icon::before {
    width: 9rem;
  }
}
.key .image-slide {
  text-align: center;
  display: flex;
  overflow: hidden;
  height: 30rem;
}
@media (min-width: 768px) {
  .key .image-slide {
    height: 45rem;
  }
}
.key .image-slide img {
  height: 100%;
  object-fit: contain;
}
.key .image-slide .image-item {
  flex-shrink: 0;
  width: 100%;
  transform: translateX(100%);
  transition: 0.5s;
}
.key .image-slide .image-item.active {
  order: -1;
  transform: translateX(0%);
}
.key .benefit-list {
  text-align: left;
  padding: var(--space-1) var(--space-2);
}
.key .benefit-head .title {
  font-size: 2rem;
  width: max-content;
  padding-inline: var(--space-2);
  border: 0.1rem solid;
  border-radius: 0.5rem;
  font-weight: 500;
  margin-bottom: var(--space-3);
}
@media (min-width: 768px) {
  .key .benefit-head .title {
    font-size: 2.6rem;
  }
}
.key .benefit-item {
  margin-block: var(--space-8);
}
.key .benefit-item.success .title {
  color: var(--success);
}
.key .benefit-item.info .title {
  color: var(--info);
}
.key .benefit-item.warning .title {
  color: var(--warning);
}
.key .benefit-item.danger .title {
  color: var(--danger);
}
.key .invest-now .text {
  color: var(--secondary);
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: var(--space-4);
}
.key.wealthcomb .image {
  width: 100%;
}
.key.wealthcomb img {
  width: 100%;
  height: 30rem;
  object-fit: contain;
}
@media (min-width: 768px) {
  .key.wealthcomb img {
    height: 50rem;
  }
}

@media (min-width: 768px) {
  .key .body {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90rem;
    margin: auto;
    margin-top: var(--space-7);
  }
  .key .left,
  .key .right {
    width: 50%;
    flex-shrink: 0;
  }
  .wealthcomb .body {
    max-width: 40rem;
    margin: auto;
  }
  .whyChooseUs .benefit-list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-6);
  }
  .whyChooseUs .benefit-item {
    margin: 0;
    max-width: 30rem;
  }
}