@use '../config/' as *;
.showcase-banner {
    min-height: calc(100vh - var(--header-height));
    padding-block: var(--space-8);
    text-align: center;
    background-image: url("../images/showcase.webp?v=#{$image-version}");
    background-size: cover;
    background-position: 0 -9rem;
    .title {
        color: var(--primary);
        .txt {
            font-size: var(--large-font);
            display: block;
            font-weight: 500;
            &:first-child {
                font-weight: 400;
            }
        }
    }
    .para {
        margin-bottom: var(--space-5);
    }
}